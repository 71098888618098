import { PhoneNumberUtil } from 'google-libphonenumber';

const isValidPhoneNumber = (phoneNumber, usOnly) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    let parsedPhoneNumber;
    if(usOnly)
      parsedPhoneNumber = phoneUtil.parse(phoneNumber, "US");
    else
      parsedPhoneNumber = phoneUtil.parse(phoneNumber, "ZZ");
      // ZZ for any phone number: https://github.com/twcclegg/libphonenumber-csharp/issues/52
    //console.log(`parsedPhoneNumber: ${JSON.stringify(parsedPhoneNumber)}`);
    console.log(`isValidPhoneNumber: ${phoneUtil.isValidNumber(parsedPhoneNumber)}`);
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  }
  catch (err) {
    console.log(err);
    return false;
  }
};

export default isValidPhoneNumber;
