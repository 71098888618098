import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { InquiryWinbackTemplate } from "src/api";

export interface InquiryWinbackSettingsState {
  templates: InquiryWinbackTemplate[];
  enabled: boolean;
  eligibleGuests: string;
  hasMaxInquiryLength: boolean;
  maxInquiryDays: number;
  preapprovalSetting: string;
  preapprovalSendExpiryMsg: boolean;
  preapprovalExpiryMsg: string | null;
  fetched: boolean;
}

const initialState: InquiryWinbackSettingsState = {
  templates: [],
  enabled: false,
  eligibleGuests: "all",
  hasMaxInquiryLength: false,
  maxInquiryDays: 0,
  preapprovalSetting: "none",
  preapprovalSendExpiryMsg: false,
  preapprovalExpiryMsg: "",
  fetched: false,
};

const inquiryWinbackSettingsSlice = createSlice({
    name: "inquiryWinbackSettings",
    initialState,
    reducers: {
      setInquiryWinbackSettings: (state, action) => {
        Object.assign(state, action.payload);
        state.fetched = true;
      },
    },
  });
  
export const {
  setInquiryWinbackSettings,
} = inquiryWinbackSettingsSlice.actions;

export const getInquiryWinbackSettings = (state: RootState) => state.inquiryWinbackSettings;

export default inquiryWinbackSettingsSlice.reducer;
