import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

import { Task } from "src/api";

export interface TasksState {
  tasks: Task[];
}

const initialState: TasksState = {
  tasks: [],
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
  },
});

export const { setTasks, } = tasksSlice.actions;

export const getTasks = (state: RootState) => state.tasks.tasks;

export default tasksSlice.reducer;
