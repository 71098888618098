import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { updateUserHasInvitedUs } from 'src/api';
import Preloader from "src/components/preloader";
import WebAccessModal from 'src/containers/webAccessModal/modal-webaccess';
import Layout from 'src/layouts';
import Content from "src/layouts/content";

const WebAccessInstructions = () => {
    const { state } = useLocation();
    const userId = state.userId;
    const pms = state.pms;
    const token = state.token;

    return (
        <Layout hideFooter={true} hasSidebar={false}>
            <Suspense fallback={<Preloader />}>
                <Content fullHeight={true} className={"page-wrapper"}>
                    <WebAccessModal userId={userId} pms={pms} token={token}/>
                </Content>
            </Suspense>
        </Layout>
    );
};

export default WebAccessInstructions;