import { Mail, PieChart, Settings, Home, Compass, DollarSign, MessageSquare } from "react-feather";
import { IMenu } from "types";

const menus: IMenu[] = [
  {
    id: 1,
    label: "Analytics",
    url: "/dashboard",
    Icon: PieChart,
    lite: true,
  },
  {
    id: 2,
    label: "Unanswered Messages",
    url: "/unanswered",
    Icon: Mail,
    lite: false,
  },
  {
    id: 3,
    label: "Listings",
    url: "/listings",
    Icon: Home,
    lite: false,
  },
  {
    id: 7,
    label: "Review Responder",
    url: "/reviews",
    Icon: MessageSquare,
    lite: false,
  }, {
    id: 8,
    label: "Tasks",
    url: "/tasks",
    Icon: MessageSquare,
    lite: false,
  },
  // {
  //     id: 4,
  //     label: "Copilot",
  //     url: "/copilot",
  //     Icon: Compass,
  // },
  {
    id: 5,
    label: "Settings",
    url: "/settings",
    Icon: Settings,
    lite: true,
  },
  /*
  {
    id: 6,
    label: "Refer & Earn $100",
    url: "/refer",
    Icon: DollarSign,
    lite: false
  },
  */
  {
    id: 9,
    label: "Widget Messages",
    url: "/widgetMessages",
    Icon: Mail,
    lite: false,
  },

];

export default menus;