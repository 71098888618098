import { configureStore, combineReducers } from "@reduxjs/toolkit";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import uiReducer from "./slices/ui";
import themeReducer from "./slices/theme";
import messagesSlice from "./slices/messages";
import widgetMessagesSlice from "./slices/widgetMessages";
import attributionsSlice from "./slices/attributions";
import globalSlice from "./slices/global";
import listingSlice from "./slices/listings";
import analyticsSlice from "./slices/analytics";
import settingsSlice from "./slices/settings";
import widgetSettingsSlice from "./slices/widgetSettings";
import autopilotSettingsSlice from "./slices/autopilotSettings";
import orphanNightsSettingsSlice from "./slices/orphanNightsSettings";
import hostNotesSlice from "./slices/hostNotes";
import earlyCheckinUpsellsSlice from "./slices/earlyCheckinUpsells";
import tasksSlice from "./slices/tasks";
import inquiryWinbackSettingsSlice from "./slices/inquiryWinbackSettings";
import copilotSlice from "./slices/copilot";

const persistConfig = {
    key: "doar",
    version: 1.1,
    blacklist: [
        "ui",
        "messages",
        "widgetMessages",
        "attributions",
        "global",
        "listings",
        "analytics",
        "settings",
        "autopilotSettings",
        "widgetSettings",
        "orphanNightsSettings",
        "hostNotes",
        "tasks",
        "inquiryWinbackSettings",
        "copilot",
    ],
    storage,
};

export const rootReducer = combineReducers({
    ui: uiReducer,
    theme: themeReducer,
    messages: messagesSlice,
    widgetMessages: widgetMessagesSlice,
    attributions: attributionsSlice,
    global: globalSlice,
    listings: listingSlice,
    analytics: analyticsSlice,
    settings: settingsSlice,
    widgetSettings: widgetSettingsSlice,
    autopilotSettings: autopilotSettingsSlice,
    orphanNightsSettings: orphanNightsSettingsSlice,
    hostNotes: hostNotesSlice,
    earlyCheckinUpsells: earlyCheckinUpsellsSlice,
    tasks: tasksSlice,
    inquiryWinbackSettings: inquiryWinbackSettingsSlice,
    copilot: copilotSlice
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;