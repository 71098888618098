import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import {
    AnalyticsData,
    ListingsData,
    ResponseTimeData,
    ListingResponse,
    MessageChain,
    OrphanData,
} from "src/api";
import { subDays } from "date-fns";

export interface AnalyticsState {
    dateRange: [string, string];
    rangeSelection: string;
    stats: {
        messagesSent: AnalyticsData;
        listings: ListingsData;
        responseTime: ResponseTimeData;
        listingResponseTimes: ListingResponse[];
        recentMessages: MessageChain[];
        orphanStats: OrphanData;
    };
}

const DEFAULT_DAYS = 31;

const getInitialDateRange = (): [string, string] => {
    const today = new Date();
    const beginDate = subDays(today, DEFAULT_DAYS);
    const endDate = today;
    return [beginDate.toISOString(), endDate.toISOString()];
};

const initialState: AnalyticsState = {
    dateRange: getInitialDateRange(),
    rangeSelection: `${DEFAULT_DAYS}`,
    stats: {
        messagesSent: { bloom: [], host: [] },
        listings: { live: 0, total: 0 },
        responseTime: { impact: 0, bestyResponse: 0, manualResponse: 0 },
        listingResponseTimes: [],
        recentMessages: [],
        orphanStats: { offersSent: 0, daysBooked: 0, revenue: 0 },
    },
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setRangeSelection: (state, action) => {
            state.rangeSelection = action.payload;
        },
        setAnalytics: (state, action: PayloadAction<any>) => {
            state.stats = action.payload;
        },
    },
});

export const { setDateRange, setRangeSelection, setAnalytics } = analyticsSlice.actions;

export const selectDateRange = (state: RootState): [string, string] =>
    state.analytics.dateRange;

export const selectRangeSelection = (state: RootState): string =>
    state.analytics.rangeSelection;

export const selectMessagesSent = (state: RootState): any =>
    state.analytics.stats.messagesSent;

export const selectListings = (state: RootState): any =>
    state.analytics.stats.listings;

export const selectResponseTime = (state: RootState): any =>
    state.analytics.stats.responseTime;

export const selectListingResponseTimes = (state: RootState): any =>
    state.analytics.stats.listingResponseTimes;

export const selectRecentMessages = (state: RootState): any =>
    state.analytics.stats.recentMessages;

export const selectOrphanStats = (state: RootState): any =>
    state.analytics.stats.orphanStats;

export default analyticsSlice.reducer;
