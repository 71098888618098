/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from "react-dom/client";
import { Suspense } from "react";

import Preloader from "./components/preloader";
import { Provider } from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import { store } from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toggle/style.css";
import App from "./App";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistProvider>
            <QueryClientProvider client={new QueryClient()}>
                <ThemeProvider>
                    <Suspense fallback={<Preloader />}>
                        <App />
                    </Suspense>
                </ThemeProvider>
            </QueryClientProvider>
        </PersistProvider>
    </Provider>
);
