// allow only the single-bracket template vals here, and also allow any double-bracket template vals, but don't allow any bad single-bracket template vals
export const isTemplateValid = (val, allowedTemplateVals) => {
  // replace every word in double {{ }}'s with empty, since those are okay to send to the PMS since those are PMS variables
  let replaced = val.replace(/{{[^{}]*}}/g, "");

  // replace any string from allowedTemplateVals with ""
  replaced = allowedTemplateVals.reduce((acc, cur) => acc.replace(new RegExp(cur, "g"), ""), replaced);

  // if there are any brackets left, bad
  return replaced.match(/[{]/g) == null;
};
