const getFormattedNumber = (number) => {
  // 1000.302 -> 1,000.30
  if (!number && number !== 0) {
    return "";
  }

  const numberString = number.toFixed(2).toString();
  const numberParts = numberString.split(".");
  const wholeNumber = numberParts[0];
  const decimal = numberParts[1];
  const wholeNumberWithCommas = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${wholeNumberWithCommas}.${decimal}`;
}

export default getFormattedNumber;