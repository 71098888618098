/**
 * Group an array by a key in its items
 * @param xs An array of objects
 * @param key The key you want to group by
 * @returns An object with keys which are distinct values of the key and values which are arrays of objects with that key
 */
function groupBy<T extends Record<PropertyKey, any>>(xs: T[], key: keyof T): Record<T[keyof T], T[]> {
  return xs.reduce((r: any, x) => ((r[x[key]] = r[x[key]] || []).push(x), r), {});
}

export default groupBy;
