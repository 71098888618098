import { authUserToken } from "../api/";

const getUser = async () => {
    let userToken = localStorage.getItem("besty_user_token");
    let loginStatus = null;
    let userId = null;

    // authenticate userId
    if (userToken) {
        const result = await authUserToken({ userToken });
        loginStatus = result.loginStatus;
        userId = result.userId;
    } 
    // send to login page if user id not in local storage
    else {
        loginStatus = 3;
    }

    return {userToken, loginStatus, userId};
};

export default getUser;
