import { Spinner } from "@doar/components";
import { StyledLoader } from "./style";

const Preloader = ({style}: {style?: React.CSSProperties}) => {
    return (
        <StyledLoader style={style}>
            <Spinner size="lg" color="primary"/>
        </StyledLoader>
    );
};

export default Preloader;
