import styled, { themeGet } from "@doar/shared/styled";
import {
    ModalHeader,
    Button,
    ModalFooter,
    Modal,
    ModalTitle,
    ModalBody,
    Select,
    Input,
} from "@doar/components";
export const CenteredModalHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    padding-bottom: 15px;
    cursor: default;
    & > * {
        margin: ${themeGet("radii.rounded")};
    }
`;

export const StyledModalBody = styled(({ ...rest }) => <ModalBody {...rest} />)`
    /* text-align: left; */
    max-height: 80vh; /* Adjust this value as needed */
    overflow-y: auto;
    overflow-x: hidden; /* Optional: prevents horizontal scrolling */
`;

export const Spacer = styled.div`
    padding: 10px;
`;

export const CenteredFooter = styled(({ ...rest }) => (
    <ModalFooter {...rest} />
))`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-top: 0;
`;

export const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
    background-color: #5b9afa;
    font-weight: 500;
`;

export const StyledModal = styled(({ ...rest }) => <Modal {...rest} />)`
    .modal-content {
        border-radius: 1rem;
    }
`;

export const StyledModalTitle = styled(({ ...rest }) => (
    <ModalTitle {...rest} />
))`
    color: #526172;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
`;

export const StyledText = styled.p`
    color: #526172;
    text-align: center;
`;

export const ClickableText = styled.span`
    &:hover {
        cursor: pointer;
    }
`;

export const StyledExit = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: #5b9afa;
    color: #fff;
    &:hover {
        cursor: pointer;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const StyledError = styled(({ isError, ...rest }) => <div {...rest} />)`
    color: red;
    font-size: 0.8rem;
    visibility: ${({ isError }) => (isError ? "visible" : "hidden")};
    padding: 5px;
    display: flex;
    justify-content: center;
`;

export const StyledSelect = styled(({ ...rest }) => <Select {...rest} />)`
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    max-width: 200px;
    margin-right: 20px;
`;
export const StyledInput = styled(({ ...rest }) => <Input {...rest} />)`
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    max-width: 250px;
`;

export const Description = styled.div`
    color: #526172;
`;

export const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BaseFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
`;

export const CheckoutWrapper = styled.div`
    background-color: white;
    display: flex;
    align-items: stretch;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 60vh;

    border-radius: 0px;
    // border: 1px solid rgba(72,94,144,0.16);
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  width: 100%;
  border: none;
`;