import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { AutopilotSettings } from "src/api";

export interface GlobalState {
    autopilotSettings: AutopilotSettings;
}

const initialState: GlobalState = {
    autopilotSettings: {
        prefixEnabled: false,
        prefix: "",
        enabled: false,
        is247: true,
        isAllListings: true,
        lowerTime: "01:00:00-00",
        upperTime: "01:00:00-00",
        isWeekendMode: false,
        weekdayLowerTime: "01:00:00-00",
        weekdayUpperTime: "01:00:00-00",
        weekendLowerTime: "01:00:00-00",
        weekendUpperTime: "01:00:00-00",
        listings: [],
        cooloff: 5,
        guestMode: "all",
        escalateMessageEnabled: false,
        escalateMessage: "",
        escalateMessageSequenceEnabled: false,
        escalateMessageSequence: ["","",""],
        prefixMode: "all",
        delay: 0,
        fetched: false
    },
};

const autopilotSettingsSlice = createSlice({
    name: "autopilotSettings",
    initialState,
    reducers: {
        setAutopilotSettings: (state, action) => {
            state.autopilotSettings = {
                ...state.autopilotSettings,
                ...action.payload,
                fetched: true,
            };
        },
    },
});

export const { setAutopilotSettings } = autopilotSettingsSlice.actions;

export const getAutopilotSettings = (state: RootState) =>
    state.autopilotSettings.autopilotSettings;

export default autopilotSettingsSlice.reducer;
