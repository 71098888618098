import { Button, Checkbox } from "@doar/components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserHasInvitedUs, getWebAccessTokensInitialized, updateUserHasInvitedUs } from "src/api";
import { StyledModal, StyledModalBody } from "./style";

const WebAccessModal = ({ userId, pms, token }: { userId: string; pms: string; token: string }) => {
  const [show, setShow] = useState(false);
  const [hasAddedBestyAccount, setHasAddedBestyAccount] = useState(false);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const capitalPms = pms.charAt(0).toUpperCase() + pms.slice(1);
  const email = `accounts+${token}@getbesty.ai`;

  const label1 = `I have invited Besty to my ${capitalPms} account (step 2)`;

  async function shouldShow() {
    getWebAccessTokensInitialized(userId).then((result) => {
      setShow(!result?.initialized);
    });
  }

  const NavigateToSettingsButton = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
      navigate("/settings");
    };

    return (
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Set up Besty
      </Button>
    );
  };

  useEffect(() => {
    if (pms === "escapia") {
      const reloadIfFinished = async () => {
        const result = await getWebAccessTokensInitialized(userId);
        if (result?.initialized) {
          window.location.href = "/";
        }
      };
      reloadIfFinished();
    }

    shouldShow();
    getUserHasInvitedUs(userId).then((result) => {
      setHasAddedBestyAccount(result?.hasInvitedUs || false);
    });
  }, []);

  const handleContinue = async () => {
    await updateUserHasInvitedUs(userId, true);
    setIsGoingBack(false);
    setHasAddedBestyAccount(true);
    setCheckbox1(false);
    setCheckbox2(false);
  };

  if (pms === "escapia") {
    return (
      <StyledModal
        show={show}
        size="lg"
        onClose={() => {
          setShow(true);
        }}
      >
        <StyledModalBody>
          <div>
            <div>
              <h1>Use Besty with {capitalPms}</h1>
              {hasAddedBestyAccount ? (
                <div
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    padding: "12px 20px",
                    borderRadius: "4px",
                    textAlign: "center",
                    margin: "10px 0",
                  }}
                >
                  You've completed these steps. A Besty agent will be in touch shortly.
                </div>
              ) : (
                <p>
                  To start using Besty with {capitalPms}, you'll need to add us as a trusted account. Follow the
                  instructions below:
                </p>
              )}
              <p>
                <u>Step 1:</u>
                <br />
                <span>Create new Besty AI user as “New Reservations Manager” by going to</span>{" "}
                <a
                  href="https://app.escapia.com/p/escapia/newreservationsmanager"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  new reservations manager page.
                </a>
                <div style={{ textAlign: "center" }}>
                  <img style={{ width: "600px", height: "auto" }} src="/escapiaStepOne.png" alt="Escapia Step One" />
                </div>
              </p>
              <p>
                <u>Step 2:</u>
                <br />
                Fill out all the fields with the following:
                <br />
                First name: <i>Besty AI</i>
                <br />
                Last name: <i>Agent</i>
                <br />
                Email: <i>{email}</i>
                <br />
                Input any phone number.
                <br />
                Press Search.
                <div style={{ textAlign: "center" }}>
                  <img style={{ width: "600px", height: "auto" }} src="/escapiaStepTwo.png" alt="Escapia Step Two" />
                </div>
              </p>
              <p>
                <u>Step 3:</u>
                <br />
                Press Next.
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "600px", height: "auto" }}
                    src="/escapiaStepThree.png"
                    alt="Escapia Step Three"
                  />
                </div>
              </p>
              <p>
                <u>Step 4:</u>
                <br />
                Create password: <i>Besty!1234</i> and press save.
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "600px", height: "auto" }}
                    src="/escapiaStepThree.png"
                    alt="Escapia Step Three"
                  />
                </div>
              </p>
              <p>You've just created an account for us, now you need to give that account proper permissions.</p>
              <p>
                <u>Step 5:</u>
                <br />
                <span>Go to the</span>{" "}
                <a
                  href="https://app.escapia.com/p/escapia/vrbosoftwarepartner"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vrbo Partner Zone
                </a>
                <span>, scroll down to "BestyAI", and select "Manage Partner":</span>
                <div style={{ textAlign: "center" }}>
                  <img style={{ width: "600px", height: "auto" }} src="/escapiaStepFive.png" alt="Escapia Step Five" />
                </div>
              </p>
              <p>
                <u>Step 6:</u>
                <br />
                <p>
                  Enable all the permissions found in the images below, then click "Save". You may need to scroll down
                  to find the "Save" button:
                </p>
                <div style={{ textAlign: "center" }}>
                  <img style={{ width: "600px", height: "auto" }} src="/escapiaStepSix.png" alt="Escapia Step Six" />
                </div>
              </p>
              <p>Once you have completed all of the steps above, click continue:</p>
              <br />
              {hasAddedBestyAccount ? (
                <div
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    padding: "12px 20px",
                    borderRadius: "4px",
                    textAlign: "center",
                    margin: "10px 0",
                  }}
                >
                  You've added Besty to your account! A Besty agent will be in touch shortly.
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await handleContinue();
                  }}
                >
                  Continue
                </Button>
              )}
            </div>
          </div>
        </StyledModalBody>
      </StyledModal>
    );
  } else {
    return (
      <StyledModal
        show={show}
        size="lg"
        onClose={() => {
          setShow(false);
        }}
      >
        <StyledModalBody>
          {hasAddedBestyAccount && !isGoingBack ? (
            <div>
              <h1>Setup Hostfully for Besty</h1>
              <p>
                Now that you've invited Besty to your {capitalPms} account, your account will be ready for use within 2
                business days. In the meantime, click "Set up Besty" below to get started.
              </p>
              <Button variant="outlined" color="secondary" onClick={() => setIsGoingBack(true)}>
                Back
              </Button>
              <NavigateToSettingsButton />
            </div>
          ) : (
            <div>
              {pms.toLowerCase() === "hostfully" ? (
                <div>
                  <h1>Setup Hostfully for Besty</h1>
                  <p>To start using Besty with {capitalPms}, follow these quick instructions:</p>
                  <p>
                    <u>Step 1:</u>
                    <br />
                    Go to your Hostfully{" "}
                    <a
                      href="https://platform.hostfully.com/app/#/agency-settings"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      agency settings page
                    </a>
                    . Scroll to the bottom of the page and make sure that under the "Activate Multi Factor
                    Authentication" section, the Authentication Type is set to "Email". Click "Update".
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "400px", height: "auto" }}
                      src="/hostfullyEmailAuthentication.png"
                      alt="Hostfully 2fa page"
                    />
                  </div>
                  <p>
                    <u>Step 2:</u>
                    <br />
                    Go to your Hostfully{" "}
                    <a
                      href="https://platform.hostfully.com/app/#/l/agency_invite.jsp?from=agency"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      invite team members page
                    </a>
                    . Select "Manager" as the "role", and under "Enter your peers' email addresses", copy and paste this
                    email: <i>{`accounts+${token}@getbesty.ai`}</i>
                    <br />
                    Then click "Send Invites".
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "400px", height: "auto" }}
                      src="/hostfullyInvite.png"
                      alt="Hostfully invite page"
                    />
                  </div>
                  <p>Once you have completed these steps, check the following boxes and click continue:</p>
                  <div>
                    <Checkbox
                      id="2FA"
                      name="2FA"
                      label="I have enabled email Multi Factor Authentication (Step 1)"
                      checked={checkbox2}
                      onChange={() => setCheckbox2(!checkbox2)}
                    />
                  </div>
                  <div>
                    <Checkbox
                      id="invited"
                      name="invited"
                      label={label1}
                      checked={checkbox1}
                      onChange={() => setCheckbox1(!checkbox1)}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    disabled={!checkbox1 || !checkbox2}
                  >
                    Continue
                  </Button>
                </div>
              ) : (
                <div>
                  <h1>Use Besty with {capitalPms}</h1>
                  <p>
                    To start using Besty with {capitalPms}, you'll need to add us as a trusted account. To do so, go
                    to... or watch the video below.
                  </p>
                  <p>Enter in this email: {`accounts+${userId}@getbesty.ai`}</p>
                  <p>Enter in this password: {"password"}</p>
                  <p>When you've added your account, click continue.</p>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await updateUserHasInvitedUs(userId, true);
                      setIsGoingBack(false);
                      setHasAddedBestyAccount(true);
                    }}
                  >
                    Continue
                  </Button>
                </div>
              )}
            </div>
          )}
        </StyledModalBody>
      </StyledModal>
    );
  }
};

export default WebAccessModal;
