import { PhoneNumberUtil } from 'google-libphonenumber';

const getPhoneCountry = (phoneNumber) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        return phoneUtil.getRegionCodeForNumber(phoneUtil.parse(phoneNumber, "US"));
    }
    catch (err) {
        return null;
    }
};

export default getPhoneCountry;
