const getTimeComponents = (timeStr) => {
    // timeStr of the form 01:00:00+00
    if (!timeStr) {
        let hours = undefined;
        let minutes = undefined;
        let period = undefined;
        let timezone = undefined;
        return {
            hours,
            minutes,
            period,
            timezone
        };
    }

    let arr = timeStr.split(":", 3);
    let end = timeStr.split(":").pop();
    if (end != arr[2]) {
        arr.push(":" + end);
    }
    else {
        arr.push("");
    }

    let hours = parseInt(arr[0]);
    let minutes = arr[1];
    let timezone = (arr[2] + arr[3]).substring(2);

    // Convert to 12 hour time
    let period = "AM";
    if (hours > 12) {
        hours -= 12;
        period = "PM";
    } else if (hours == 12) {
        period = "PM";
    } else if (hours == 0) {
        hours = 12;
    }

    if (hours < 10) {
        hours = "0" + hours;
    }

    hours = hours.toString();

    return {
        hours,
        minutes,
        period,
        timezone
    };
}

export default getTimeComponents;