import { createSlice } from "@reduxjs/toolkit";
import { Listing } from "src/api/";
import { RootState } from "src/redux/store";

export interface ListingsState {
    listings: Listing[];
    firstTime: boolean;
    selectedListingId: string;
    toggleInfo: {
        listings: string[];
        action: "enable" | "disable" | "enableAll" | "disableAll" | "";
    };
    pendingBestyResponses: string[];
}

const initialState: ListingsState = {
    listings: [],
    selectedListingId: "",
    toggleInfo: { listings: [], action: "" },
    firstTime: false,
    pendingBestyResponses: [],
};

const listingsSlice = createSlice({
    name: "listings",
    initialState,
    reducers: {
        setFirstTime: (state, action) => {
            state.firstTime = action.payload;
        },
        setListings: (state, action) => {
            state.listings = action.payload.listings;
            state.firstTime = action.payload.firstTime;
            state.selectedListingId =
                action.payload.listings.length > 0
                    ? action.payload.listings[0].id
                    : "";
        },
        setSelectedListingId: (state, action) => {
            state.selectedListingId = action.payload;
        },
        enableAllListings: (state, action) => {
            state.listings = state.listings.map((listing) => {
                return {
                    ...listing,
                    enabled: true,
                };
            });
        },
        disableAllListings: (state, action) => {
            state.listings = state.listings.map((listing) => {
                return {
                    ...listing,
                    enabled: false,
                };
            });
        },
        toggleListing: (state, action) => {
            const listing = state.listings.find(
                (listing) => listing.id === action.payload
            );
            if (listing) {
                listing.enabled = !listing.enabled;
            }
        },
        setEmbeddingFalse: (state, action) => {
            const listing = state.listings.find(
                (listing) => listing.id === action.payload
            );
            if (listing) {
                listing.embedded = false;
            }
        },
        addMessage: (state, action) => {
            const listing = state.listings.find(
                (listing) => listing.id === action.payload.listingId
            );
            listing?.thread.push(action.payload.message);
        },
        setListingsToToggle: (state, action) => {
            state.toggleInfo = action.payload;
        },
        addPendingBestyResponse: (state, action) => {
            state.pendingBestyResponses.push(action.payload);
        },
        removePendingBestyResponse: (state, action) => {
            state.pendingBestyResponses = state.pendingBestyResponses.filter((id) => id != action.payload);
        }
    },
});

export const {
    setListings,
    setSelectedListingId,
    disableAllListings,
    enableAllListings,
    toggleListing,
    setEmbeddingFalse,
    addMessage,
    setListingsToToggle,
    setFirstTime,
    addPendingBestyResponse,
    removePendingBestyResponse,
} = listingsSlice.actions;

export const selectListings = (state: RootState) => state.listings.listings;
export const getSelectedListingId = (state: RootState) =>
    state.listings.selectedListingId;

export const getFirstTime = (state: RootState) => state.listings.firstTime;

export const getToggleInfo = (state: RootState) => state.listings.toggleInfo;

export const getSelectedListing = (state: RootState) =>
    state.listings.listings.find(
        (listing) => listing.id === state.listings.selectedListingId
    ) || {};

export const getPendingBestyResponses = (state: RootState) => state.listings.pendingBestyResponses;

export default listingsSlice.reducer;
