import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { HostNote } from "src/api";

export interface HostNotesState {
  hostNotes: HostNote[];
}

const initialState: HostNotesState = {
  hostNotes: [],
};

const hostNotesSlice = createSlice({
  name: "hostNotes",
  initialState,
  reducers: {
    setHostNotes: (state, action) => {
      state.hostNotes = action.payload;
    },
    addHostNote: (state, action) => {
      if (state.hostNotes.some((hostNote) => hostNote.id === action.payload.id)) {
        state.hostNotes = state.hostNotes.map((hostNote) =>
          hostNote.id === action.payload.id ? action.payload : hostNote
        );
      } else {
        state.hostNotes.push(action.payload);
      }
    },
    removeHostNote: (state, action) => {
      state.hostNotes = state.hostNotes.filter(
        (hostNote) => hostNote.id !== action.payload
      );
    }
  },
});

export const {
  setHostNotes,
  addHostNote,
  removeHostNote,
} = hostNotesSlice.actions;

export const getHostNotes = (state: RootState) => state.hostNotes.hostNotes;

export default hostNotesSlice.reducer;
