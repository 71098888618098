import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { addMessage } from "./messages";

interface WidgetChatMessage {
    role: 'user' | 'assistant';
    content: string;
    sender: 'guest' | 'host' | 'ai';
    timestamp: number;
}

interface WidgetChat {
    chat_id: string;
    history: WidgetChatMessage[];
}

interface WidgetCurrentSessionChats {
    session_id: string | null;
    chat_id: string | null; // current chat id
    chats: { [key: string]: WidgetChat }; // Using object instead of Map
}

interface WidgetSession {
    session_id: string;
    email: string;
    phone_number: string;
    guest_messages : number;
    last_message_timestamp: number;
}

export interface WidgetMessagesState {
    current_session_chats: WidgetCurrentSessionChats;
    widget_sessions: { [key: string]: WidgetSession }; // Using object instead of Map
    earliest_session_loaded: number;
    requested_load: boolean;
}

const initialState: WidgetMessagesState = {
    current_session_chats: {
        session_id: null,
        chat_id: null,
        chats: {}
    },
    widget_sessions: {},
    earliest_session_loaded: Date.now(),
    requested_load: false
};

function newCurrentSessionChatMessage(state: WidgetMessagesState, chat_id: string, message: WidgetChatMessage) {
    if (!state.current_session_chats.chats[chat_id]) {
        state.current_session_chats.chats[chat_id] = { chat_id, history: [] };
    }
    state.current_session_chats.chats[chat_id].history.push({
        role: message.role,
        content: message.content,
        sender: message.sender,
        timestamp: message.timestamp
    });
    const current_session_id = state.current_session_chats.session_id;
    if (current_session_id) {
        const session = state.widget_sessions[current_session_id];
        if (session) {
            session.last_message_timestamp = Date.now();
        }
    }
}

const widgetMessagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        setCurrentSessionID: (state, action) => {
            const { session_id } = action.payload;
            state.current_session_chats.session_id = session_id;
            state.current_session_chats.chats = {};
        },
        setCurrentChatID: (state, action) => {
            const { chat_id } = action.payload;
            state.current_session_chats.chat_id = chat_id;
        },
        requestLoadSessions: (state, action) => {
            state.requested_load = true;
        },
        // websocket actions
        setCurrentSession: (state, action) => {
            const { session_id, chats } = action.payload;
            state.current_session_chats.session_id = session_id;
            state.current_session_chats.chats = Object.fromEntries(
                chats.map((chat: WidgetChat) => [chat.chat_id, chat])
            );
            state.current_session_chats.chat_id = chats.length > 0 ? [...chats].sort((a, b) => b.history[b.history.length - 1].timestamp - a.history[a.history.length - 1].timestamp)[0].chat_id : null;
        },
        newCurrentSessionChat: (state, action) => {
            const { chat_id } = action.payload;
            state.current_session_chats.chats[chat_id] = {
                chat_id,
                history: []
            };
            const current_session_id = state.current_session_chats.session_id;
            if (current_session_id) {
                const session = state.widget_sessions[current_session_id];
                if (session) {
                    session.last_message_timestamp = Date.now();
                }
            }
        },
        newCurrentSessionGuestMessage: (state, action) => {
            const { chat_id, question } = action.payload;
            newCurrentSessionChatMessage(state, chat_id, {
                role: 'user',
                content: question,
                sender: 'guest',
                timestamp: Date.now()
            });
        },
        newCurrentSessionHostMessage: (state, action) => {
            const { chat_id, response } = action.payload;
            newCurrentSessionChatMessage(state, chat_id, {
                role: 'assistant',
                content: response,
                sender: 'host',
                timestamp: Date.now()
            });
        },
        newCurrentSessionAiMessage: (state, action) => {
            const { chat_id, response } = action.payload;
            newCurrentSessionChatMessage(state, chat_id, {
                role: 'assistant',
                content: response,
                sender: 'ai',
                timestamp: Date.now()
            });
        },
        loadWidgetSessions: (state, action) => {
            const {
                sessions,
                earliest_timestamp
            } = action.payload;
            for (const session of sessions) {
                state.widget_sessions[session.session_id] = session;
            }
            state.earliest_session_loaded = earliest_timestamp;
            state.requested_load = false;

        },
        sessionUpdate: (state, action) => {
            const { session_id, email, phone_number, change_guest_messages, last_message_timestamp } = action.payload;
            const session = state.widget_sessions[session_id];
            if (session) {
                session.guest_messages = change_guest_messages ? session.guest_messages + 1 : session.guest_messages;
                session.last_message_timestamp = last_message_timestamp;
            }else{
                state.widget_sessions[session_id] = {
                    session_id,
                    email,
                    phone_number,
                    guest_messages: 1,
                    last_message_timestamp
                }
            }
        }
    },
});

export const {
    setCurrentSessionID,
    setCurrentChatID,
    requestLoadSessions,
    setCurrentSession,
    newCurrentSessionChat,
    newCurrentSessionGuestMessage,
    newCurrentSessionHostMessage,
    newCurrentSessionAiMessage,
    loadWidgetSessions,
    sessionUpdate
} = widgetMessagesSlice.actions;

export const getCurrentSessionID = (state: RootState) =>
    state.widgetMessages.current_session_chats.session_id;
export const getSessions = (state: RootState) =>
    state.widgetMessages.widget_sessions;
export const getCurrentSessionInfo = (state: RootState) => {
    const session_id = state.widgetMessages.current_session_chats.session_id;
    return session_id ? state.widgetMessages.widget_sessions[session_id] : null;
}
export const getCurrentSessionChats = (state: RootState) =>
    state.widgetMessages.current_session_chats.chats;
export const getCurrentChatID = (state: RootState) =>
    state.widgetMessages.current_session_chats.chat_id;
export const getCurrentChat = (state: RootState) => {
    const chat_id = state.widgetMessages.current_session_chats.chat_id;
    return chat_id ? state.widgetMessages.current_session_chats.chats[chat_id] : null;
}
export const getRequestedLoad = (state: RootState) =>
    state.widgetMessages.requested_load;
export const getEarliestSessionLoaded = (state: RootState) =>
    state.widgetMessages.earliest_session_loaded;
export const getWidgetMessagesState = (state: RootState) =>
    state.widgetMessages;
export default widgetMessagesSlice.reducer;