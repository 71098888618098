import { createSlice } from "@reduxjs/toolkit";
import { Attribution } from "src/api/";
import { RootState } from "src/redux/store";
import { localeCurrencyCode } from "src/utils/country-to-currency";
import groupBy from "src/utils/group-by";

export interface AttributionsState {
  attributionsById: { [id: Attribution["id"]]: Attribution };
  selectedId: Attribution["id"];
  offerCount: { [attributionType in keyof typeof attributionTypes]: number };
}

const initialState: AttributionsState = {
  attributionsById: {},
  selectedId: "",
  offerCount: {} as AttributionsState["offerCount"],
};

export const attributionTypes = {
  "Booking booster": ["orphan", "bookend"],
  "Early checkin": ["early_checkin"],
  "Inquiry winback": ["winback"],
  Retargeting: ["retargeting"],
};

const attributionsSlice = createSlice({
  name: "attributions",
  initialState,
  reducers: {
    setAttributions: (state, action) => {
      const attributionsById: AttributionsState["attributionsById"] = {};
      const attributionsByAttributionId: { [id: string]: Attribution } = {};
      const attributions: Attribution[] = action.payload.attributions;
      for (const attribution of attributions) {
        if (!attribution?.thread?.thread && !["winback", "retargeting"].includes(attribution.attributionType)) {
          continue;
        }
        attribution.thread?.thread?.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        if (attribution.attributionType === "orphan") {
          const duplicate = attributionsByAttributionId[attribution.attributionId];
          if (duplicate && duplicate.attributionType === "orphan") {
            if (!attribution.accepted) {
              continue;
            } else if (duplicate.accepted) {
              duplicate.otherBookendThread = attribution.thread;
              continue;
            } else if (!duplicate.accepted) {
              delete attributionsByAttributionId[duplicate.attributionId];
              delete attributionsById[duplicate.id];
            }
          }
        }

        attributionsById[attribution.id] = attribution;
        attributionsByAttributionId[attribution.attributionId] = attribution;
      }
      state.attributionsById = attributionsById;
      state.offerCount = action.payload.offerCount;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload in state.attributionsById ? action.payload : "";
    },
    rejectAttribution: (state, action) => {
      const { [action.payload]: _, ...otherAttributionsById } = state.attributionsById;
      state.attributionsById = otherAttributionsById;
      state.selectedId = Object.values(otherAttributionsById)[0]?.id || "";
    },
  },
});

export const { setAttributions, setSelectedId, rejectAttribution } = attributionsSlice.actions;

export const getSelectedId = (state: RootState) => {
  return state.attributions.selectedId;
};

export const getThreadIds = (state: RootState) => {
  return selectAttributions(state).map((a) => a.thread?.threadId);
};

export const selectAttributions = (state: RootState) => {
  return Object.values(state.attributions.attributionsById);
};

export const selectAttributionsByMessageType = (state: RootState) => {
  const attributionsByMessageType: { [type: string]: Attribution[] } = {};
  for (const type of Object.keys(attributionTypes)) {
    attributionsByMessageType[type] = selectAttributions(state)
      .filter((a) => a.accepted && (a.thread || ["Inquiry winback", "Retargeting"].includes(type)))
      .filter((a) => attributionTypes[type as keyof typeof attributionTypes].includes(a.attributionType));
  }
  return attributionsByMessageType;
};

export const selectAttributionsTable = (state: RootState) => {
  const attributions = selectAttributions(state);
  function getTableRow(name: keyof typeof attributionTypes, types: string[]) {
    const data = attributions.filter((a) => types.includes(a.attributionType));
    const offers = state.attributions.offerCount[name];
    const processed = data.length;
    const accepted = data.filter((a) => a.accepted);
    const acceptedCount = accepted.length;
    const revenue = getRevenueTotalString(accepted);
    return [name, offers, processed, acceptedCount, revenue];
  }
  return Object.entries(attributionTypes).map(([name, types]) => getTableRow(name as keyof typeof attributionTypes, types));
};

const localeCurrencyFirst = (a: string, b: string) => (a === localeCurrencyCode ? -1 : a.localeCompare(b));
function getRevenueTotalString(attributions: Attribution[]): string {
  const noNullAttributions = attributions.map(function(obj) {
    const newObj = {...obj};
    if (!newObj.currencyCode) {
      newObj.currencyCode = 'Unknown';
    }
    return newObj;
  });
  console.log("nullCount", noNullAttributions.filter(item => item.currencyCode==null).length);
  const attrsByCurrency = groupBy(noNullAttributions, "currencyCode");
  const currencyCodes = Object.keys(attrsByCurrency).sort(localeCurrencyFirst);
  const currencyStrings = currencyCodes.map((currency) => {
    const total = attrsByCurrency[currency].reduce((acc: number, curr) => acc + curr.revenue, 0);
    try {
      if (currency == "Unknown") {
        return "¤ " + total.toFixed(2);
      }
      return total.toLocaleString(undefined, { style: "currency", currency });
    } catch (e) { // Sometimes currency_code from the DB is null
      if (e)
      console.error(e);
      console.log(attrsByCurrency[currency]);
    }
  });
  return currencyStrings.filter(Boolean).join(" + ");
}

export const getSelectedAttribution = (state: RootState) => {
  return state.attributions.attributionsById[state.attributions.selectedId];
};

export default attributionsSlice.reducer;
