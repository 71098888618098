
import { createSlice } from "@reduxjs/toolkit";
import { EarlyCheckinUpsell } from "src/api";
import { RootState } from "src/redux/store";

export interface EarlyCheckinUpsellsState {
  earlyCheckinUpsells: EarlyCheckinUpsell[];
  
}

const initialState: EarlyCheckinUpsellsState = {
  earlyCheckinUpsells: [],
};

const earlyCheckinUpsellsSlice = createSlice({
  name: "earlyCheckinUpsells",
  initialState,
  reducers: {
    setEarlyCheckinUpsells: (state, action) => {
      state.earlyCheckinUpsells = action.payload;
    },
    addEarlyCheckinUpsell: (state, action) => {
      if (state.earlyCheckinUpsells.some((earlyCheckinUpsell) => earlyCheckinUpsell.id === action.payload.id)) {
        state.earlyCheckinUpsells = state.earlyCheckinUpsells.map((earlyCheckinUpsell) =>
          earlyCheckinUpsell.id === action.payload.id ? action.payload : earlyCheckinUpsell
        );
      } else {
        state.earlyCheckinUpsells.push(action.payload);
      }
    },
    removeEarlyCheckinUpsell: (state, action) => {
      state.earlyCheckinUpsells = state.earlyCheckinUpsells.filter(
        (earlyCheckinUpsell) => earlyCheckinUpsell.id !== action.payload
      );
    }
  },
});

export const {
  setEarlyCheckinUpsells,
  addEarlyCheckinUpsell,
  removeEarlyCheckinUpsell,
} = earlyCheckinUpsellsSlice.actions;

export const getEarlyCheckinUpsells = (state: RootState) => state.earlyCheckinUpsells.earlyCheckinUpsells;

export default earlyCheckinUpsellsSlice.reducer;
