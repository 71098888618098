import { parseISO, format } from "date-fns";

const prettifyDate = (timestamp) => {

  // if timestamp is today, return "today"
  // if timestamp is yesterday, return "yesterday"
  // if timestamp is this year, return "month/day"
  // else, write "year/month/day"

  // https://date-fns.org/v2.30.0/docs/format
  const parsed = parseISO(timestamp);
  const time = format(parsed, "h:mm aa");

  const date = format(parsed, "LLL d, yyyy");
  const today = format(new Date(), "LLL d, yyyy");
  const yesterday = format(new Date(Date.now() - 86400000), "LLL d, yyyy");
  const year = format(parsed, "yyyy");
  const thisYear = format(new Date(), "yyyy");
  const monthDay = format(parsed, "LLL d");

  let day;
  if (date === today) {
    day = "today";
  }
  if (date === yesterday) {
    day = "yesterday";
  }
  if (year === thisYear) {
    day = `on ${monthDay}`;
  }
  else {
    day = `on ${date}`;
  }
  return `${day} at ${time}`;
};

export default prettifyDate;