import { createSlice } from "@reduxjs/toolkit";
import { CopilotMessage, CopilotThread } from "src/api";
import { RootState } from "src/redux/store";

export interface CopilotState {
  threads: CopilotThread[];
  selectedThreadId: string;
  pendingBestyResponses: string[];
}

const initialState: CopilotState = {
  threads: [],
  selectedThreadId: "",
  pendingBestyResponses: [],
};

const copilotSlice = createSlice({
  name: "copilot",
  initialState,
  reducers: {
    setCopilotThreads: (state, action) => {
      state.threads = action.payload.threads;
      state.selectedThreadId =
        action.payload.threads.length > 0
          ? action.payload.threads[0].id
          : "";
    },
    setCopilotThreadLastUpdated: (state, action) => {
      const thread = state.threads.find(
        (t) => t.id === action.payload.threadId
      );
      if (!thread) return;
      thread.lastUpdated = action.payload.lastUpdated;
    },
    removeCopilotThread: (state, action) => {
      const threads = state.threads.filter(
        (thread) => thread.id !== action.payload.threadId
      );

      return { ...state, threads, selectedThreadId: threads.length > 0 ? threads[0].id : "" };
    },
    removeConfirmation: (state, action) => {
      const thread = state.threads.find(t => t.id === action.payload.threadId);
      if (!thread?.messages) {
        console.log("none");
        return;
      }
      thread.messages = thread.messages.filter(m => m.confirmationId !== action.payload.confirmationId);
    },
    addCopilotThread: (state, action) => {
      state.threads.unshift(action.payload);
      state.selectedThreadId = action.payload.id;
    },
    setSelectedCopilotThread: (state, action) => {
      state.selectedThreadId = action.payload;
    },
    addMessage: (state, action) => {
      const thread = state.threads.find(
        (t) => t.id === action.payload.threadId
      );
      thread?.messages.push(action.payload.message);
    },
    addPendingBestyResponse: (state, action) => {
      state.pendingBestyResponses.push(action.payload);
    },
    removePendingBestyResponse: (state, action) => {
      state.pendingBestyResponses = state.pendingBestyResponses.filter((id) => id != action.payload);
    }
  },
});

export const { setCopilotThreads, setSelectedCopilotThread, addMessage, addPendingBestyResponse, removePendingBestyResponse, addCopilotThread, removeCopilotThread, setCopilotThreadLastUpdated, removeConfirmation } = copilotSlice.actions;

export const getCopilotThreads = (state: RootState) => state.copilot.threads;

export const getSelectedCopilotThread = (state: RootState) => state.copilot.threads.find(t => t.id === state.copilot.selectedThreadId) || {};

export const getSelectedCopilotThreadId = (state: RootState) => state.copilot.selectedThreadId;

export const getPendingBestyResponses = (state: RootState) => state.copilot.pendingBestyResponses;

export default copilotSlice.reducer;
