import { useCallback } from "react";
import { IMenu } from "@doar/shared/types";
import { getSiblings } from "@doar/shared/methods";
import { useClickOutside } from "@doar/shared/hooks";
import { StyledNavbar, StyledNavitem, StyledNavlink, RedCircle } from "./style";
import Submenu from "./submenu";
import Megamenu from "./megamenu";
import isPmsLite from "@doar/main/src/utils/is-pms-lite";
import { useAppSelector } from '@doar/main/src/redux/hooks';
import { getUserInfo } from '@doar/main/src/redux/slices/global';
interface IProps {
    menus: IMenu[];
    messageCount?: number;
    userId: string;
    pms: "hostaway" | "guesty" | "hostfully";
}

const COPILOT_USER_IDS = ["305", "175", "234", "221", "242", "239", "266", "181", "177", "145", "146", "163", "200", "279"];

const REVIEW_RESPONDER_PMS = ['guesty']

const Navbar = ({ userId, menus, messageCount, pms }: IProps) => {
    const { pms: userPms, widgetEnabled } = useAppSelector(getUserInfo);
    if (!widgetEnabled) {
        menus = menus.filter(menu => menu.url !== "/widgetMessages");
    }
    const clickHandler = (e: React.MouseEvent, hasChildren: boolean) => {
        if (hasChildren) {
            e.preventDefault();
            let target = e.currentTarget as HTMLElement;
            if (target.nodeName === "A") {
                target = target.parentElement as HTMLElement;
            }
            const submenu = target.querySelector(".submenu");
            const siblings = getSiblings(target);
            submenu?.classList.toggle("open");
            siblings.forEach((sib) => {
                sib.childNodes.forEach((child) => {
                    const childHT = child as HTMLElement;
                    childHT?.classList?.remove("open");
                });
            });
        }
    };
    const onClose = useCallback(() => {
        const nav = document.querySelector(".navbar");
        const submenu = nav?.querySelectorAll(".submenu");
        submenu?.forEach((el) => el.classList.remove("open"));
    }, []);

    // was: <RedCircle>{messageCount}</RedCircle>
    const containerRef = useClickOutside<HTMLUListElement>(onClose);

    return (
        <StyledNavbar ref={containerRef} className="navbar">
            {menus &&
                menus.map((nav: IMenu) => {
                    const {
                        submenu,
                        megamenu,
                        id: navId,
                        Icon: NavIcon,
                        label: navLabel,
                    } = nav;
                    const hasSubmenu = !!submenu?.length;
                    const hasMegamenu = !!megamenu?.length;
                    const hasChildren = hasSubmenu || hasMegamenu;

                    if (nav.url === "/copilot" && !(COPILOT_USER_IDS.includes(userId + ""))) {
                        return null;
                    }

                    if (nav.url === "/reviews") {
                        if (userPms !== 'guesty') return null;
                    }

                    if (nav.url === "/review-tasks") {
                        if (!['guesty', 'hostaway'].includes(userPms)) return null;
                    }

                    if (!nav.lite && isPmsLite(userPms)) return null;

                    return (
                        <StyledNavitem
                            $hasSubmenu={hasChildren}
                            key={navId}
                            onClick={(e) => clickHandler(e, hasChildren)}
                        >
                            <StyledNavlink path={hasChildren ? "#!" : nav.url}>
                                {NavIcon && <NavIcon />}
                                {navLabel}
                                {nav.label === "Unanswered Messages" &&
                                    (messageCount || 0) > 0 && (
                                        <> &nbsp;({messageCount}) </>
                                    )}
                            </StyledNavlink>
                            {submenu && <Submenu menu={submenu} />}
                            {megamenu && <Megamenu menu={megamenu} />}
                        </StyledNavitem>
                    );
                })}
        </StyledNavbar>
    );
};

Navbar.defaultProps = {
    menus: [],
};

export default Navbar;