import axios from "axios";

// See README for details on env var
export const API_ENDPOINT = process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_DEV_HOST : process.env.REACT_APP_HOST;
console.log(`API endpoint: ${API_ENDPOINT}`);

const HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

export const client = axios.create({
    baseURL: API_ENDPOINT,
    headers: HEADERS,
});

export const WEBSOCKET_URL = process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_DEV_WS : process.env.REACT_APP_WS;
